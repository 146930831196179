import React from 'react';
import BlockArticle from '../components/BlockArticle';
import './About.css';

const About = () => {
    return (
<div className="page-container">
    <div className="top-separator" />
    <div className="page-info">
        <p>The <strong>About me </strong> section is a bit like the b-side of a casette tape (showing my age) and contains some interesting facts about me that might not make it onto my printable resume.</p>
    </div>
    <section className="about-article">
        <BlockArticle className="about-article" articleImage="./images/elephant.png" articleHeader="Brief summary"
            articleBody="I was born and lived most of my life in South Africa. My family and I immigrated to Australia in November 2019 and have settled in well in our beautiful suburb of Beaumont Hills in the Hills district of Sydney.
            I love creating software and being involved in the complete software creation journey, from conceptualizing, to maintaining it and every step in-between. 
            I have filled various roles, within the business software space, over the last 20+ years. I have continued to remain proficient in both the hard skills required to write and develop code as well as the soft skills to manage projects, people and processes.
            Away from work I am a family man and love being a father to 2 gorgeous girls, a loving husband to my beautiful wife, son to an amazing mom and brother, funcle (fun-uncle), pet owner etc. I love the game of tennis and also exploring exciting waterways with our new family pet, our jet ski." />
    </section>
    <section className="about-article">
        <BlockArticle className="about-article" articleImage="./images/goal.png" articleHeader="Goal for the year"
            articleBody="My goal for the year is to continue honing my craftmanship in software engineering, to improve my fitness and health and enjoy quality family and leisure time." />
    </section>
    <section className="about-article">
        <BlockArticle className="about-article" articleImage="./images/podcast.png" articleHeader="Favourite Pod-casts"
            articleBody="For software development I enjoy listing to episides from Software Engineering Radio, Front End HAPPY HOUR, Javascript Jabber, JS Party, React Roundup, ShopTalk and Syntax. The topics are very relevant and covers the latest trends and technologies. The interviewers and interviewees are really knowledgable on their area of expertise and I stay up-to-date as well as learn new concepts to explore further.
            Outside of software podcasts I absolutely and have always loved listening to Lewis Howes' School of Greatness and the amazing interviewees he introduces to his audience. He is a lifestyle entrepreneur and his though-provoking, perception challenging and heartfelt interviews with amazing experts, has seen my health improving, my goal weight achieved, and has pushed me to continuously strive for greatness. In a similar vein, I also enjoy listening to 'The Tim Ferriss Show'"
        />
    </section>
    <section className="about-article">
        <BlockArticle className="about-article" articleImage="./images/rocket.png" articleHeader="Pet project"
            articleBody="I'm busy building a virtual board and the game controller for various boardgame-like games.
            The solution is written in React with Typescript, while the database is a mySql database and I created an api, using PHP, to communicate with the database and be called from the web applications.
            It is a work in progress and I'm working on completing the first game on the platform" 
            articleLink1 = "http://vbg.leonardbauling.com"
            articleLink1Name = "Virtual boardgame - WIP"
        />
    </section>
</div>
    );
}

export default About;