import React from 'react';
import { NavLink } from 'react-router-dom';
import './Card.css';

const Card = (props) => {
    const navLinkText = `See ${props.title}`;
    return (
        <div className="card-container">
            <div className="card-image">
                <img src={props.cardImage} alt='' />
            </div>
            <div className="card-content">
                <div className="card-title">
                    <h3>{props.title}</h3>
                </div>
                <div className="card-body">
                    <p>{props.content}</p>
                </div>
            </div>
            <div className="card-button">
                <button>
                    <NavLink className="card-link" to={props.to} exact="true">{navLinkText}</NavLink>
                </button>
            </div>
        </div>
    );
}

export default Card;