import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { NavLink } from 'react-router-dom';
import gsap from 'gsap';

const Navbar = () => {
    const [isOpen, setOpen] = useState(false);
    const [isFade, setFade] = useState(true);
    useEffect(() => {
        gsap.set(".logo-link", {transformOrigin: "50%", delay: 1});
        gsap.to(".logo-link", { duration: 2, delay: 1, rotationY: 360, ease:"slow"});
    });
    return (
        <nav>
            <NavLink className="logo-link" to="/" exact="true">Leonard Bauling</NavLink>
            <div className="hamburger" onClick={() => {
                setOpen(!isOpen);
                setFade(!isFade);
            }}>
                <div className="line" ></div>
                <div className="line" ></div>
                <div className="line" ></div>
            </div>
            <ul className={isOpen ? "nav-links open" : "nav-links"} >
                <li><NavLink className={isFade ? "nav-link" : "nav-link fade"} to="/" exact="true" onClick={() => {
                    setOpen(!isOpen);
                    setFade(!isFade);
                    }}>Home</NavLink></li>
                <li><NavLink className={isFade ? "nav-link" : "nav-link fade"} to="/skills" exact="true" onClick={() => {
                    setOpen(!isOpen);
                    setFade(!isFade);
                    }}>My Skills</NavLink></li>
                <li><NavLink className={isFade ? "nav-link" : "nav-link fade"} to="/education" exact="true" onClick={() => {
                    setOpen(!isOpen);
                    setFade(!isFade);
                    }}>My Education</NavLink></li>
                <li><NavLink className={isFade ? "nav-link" : "nav-link fade"} to="/experience" exact="true" onClick={() => {
                    setOpen(!isOpen);
                    setFade(!isFade);
                    }}>My Experience</NavLink></li>
                <li><NavLink className={isFade ? "nav-link" : "nav-link fade"} to="/about" exact="true" onClick={() => {
                    setOpen(!isOpen);
                    setFade(!isFade);
                    }}>About Me</NavLink></li>
            </ul>
        </nav>
    );
}

export default Navbar;