import React from 'react';
import './GraphLine.css';

const GraphLine = (props) => {
    const colorClass = `graphline-bar ${props.color} perc${props.value}`;
    return (
        <div className="graphline-container">
            <div className={colorClass}>
                <p>{props.name}</p>
            </div>
            <h4>{props.value}</h4>
            <p>{props.attribute}</p>
        </div>
    );
}

export default GraphLine;