import React from 'react';
import './Education.css';

const Education = () => {
    return (
<div className="page-container">
    <div className="top-separator" />
    <div className="page-info">
        <p><strong>My education </strong> achievements are listed on this page, including certificates, diplomas and endorsements.</p>
    </div>
    <div className="timeline">
        <div className="timeline-container timeline-left">
            <div className="timeline-content">
                <h3>2021</h3>
                <ul>
                    <li>Learn CSS learning path</li>
                    <p><strong>LinkedIn Learning</strong></p>
                    <li><span>37 Courses (JS, TS, CSS, Node, React, Vue, Databases, APIs etc.) 
                        <img className="icon-smallest" src="./images/info-icon.png" alt="Info icon: Hover over me to see list of courses"
                        title='Complete Intro to Real-Time - Brian Holt
AWS for Front-End Engineers (ft. S3, Cloudfront & Route 53) - Steve Kinney
VIM Fundamentals - ThePrimeagen
Complete Intro to Linux and the Command-Line - Brian Holt
Web Assembly - Jem Young
SVG Essentials & Animation, v2 - Sarah Drasner
CSS In-Depth, v2 - Estelle Weyl
Design for Developers - Sarah Drasner
CSS Grid & Flexbox for Responsive Layouts, v2 - Jen Kramer
Complete Intro to Databases - Brian Holt
Design Systems with React & Storybook - Emma Bostian
Responsive Web Typography v2 - Jason Pamental
State Machines in JavaScript with Xstate - David Khourshid
Full Stack for Front-End Engineers, v2 - Jem Young
JavaScript and TypeScript Monorepos - Mike North
Functional JavaScript First Steps - Anjana Vakil
Hardcore Functional Programming in JavaScript, v2 - Brian Lonsdorf
JavaScript Performance - Steve Kinney
Asynchronous Programming in JavaScript (with Rx.js Observables) - Jafar Husain
JavaScript: The New Hard Parts - Will Sentence
TypeScript Fundamentals, v3 - Mike North
Functional-Light JavaScript, v3 - Kyle Simpson
JavaScript: The Recent Parts - Kyle Simpson
Deep JavaScript Foundations, v3 - Kyle Simpson
JavaScript: The Hard Parts, v2 - Will Sentence
TypeScript 3 Fundamentals, v2 - Mike North
Complete Intro to Containers (feat. Docker) - Brian Holt
Introduction to Next.js - Scott Moss
API Design in Node.js, v3 - Scott Moss
The Hard Parts of Servers & Node.js - Will Sentence
Introduction to Node.js, v2 - Scott Moss
Complete Intro to React, v6 - Brian Holt
Git In-depth - Nina Zakharenko
Production-Grade Vue.js - Ben Hong
Introduction to Vue.js - Sarah Drasner
Complete Intro to Web Development, v2 - Brian Holt
Web Performance Fundamentals - Tod Gardner
                        '></img></span>
                    </li>
                    <p><strong>Frontend Masters</strong></p>
                </ul>
            </div>
        </div>
        <div className="timeline-container timeline-right">
            <div className="timeline-content">
                <h3>2020</h3>
                <ul>
                    <li>Resilience at work Accreditation<br/>
                        <strong>ripen</strong>
                    </li>
                    <li>Universal Principles of Design</li>
                    <p><strong>LinkedIn Learning</strong></p>
                </ul>
            </div>
        </div>
        <div className="timeline-container timeline-left">
            <div className="timeline-content">
                <h3>2019</h3>
                <ul>
                    <li>Successful assessment as ‘Developer Programmer’<br/>
                        <strong>Australian Computer Science Inc.</strong>
                    </li>
                </ul>
            </div>
        </div>
        <div className="timeline-container timeline-right">
            <div className="timeline-content">
                <h3>2014</h3>
                <ul>
                    <li>Competent Communicator<br/>
                        <strong>Toastmasters International</strong>
                    </li>
                </ul>
            </div>
        </div>
        <div className="timeline-container timeline-left">
            <div className="timeline-content">
                <h3>2013</h3>
                <ul>
                    <li>Microsoft Specialist: Programming with C#</li>
                    <li>MCSD: Microsoft Certified Solutions Developer: Windows Store Apps</li>
                    <li>MCT: Microsoft Certified Trainer</li>
                    <p><strong>Microsoft</strong></p>
                </ul>
            </div>
        </div>
        <div className="timeline-container timeline-right">
            <div className="timeline-content">
                <h3>2012</h3>
                <ul>
                    <li>TOGAF 9.1 Certification</li>
                    <p><strong>The Open Group</strong></p>
                    <li>MCTS: Windows Communication Foundation Development  with Microsoft.NET Framework</li>
                    <li>MCTS: Accessing Data with Microsoft .NET Framework 4</li>
                    <li>MCSD: Microsoft Certified Professional Developer</li>
                    <p><strong>Microsoft</strong></p>
                </ul>
            </div>
        </div>
        <div className="timeline-container timeline-left">
            <div className="timeline-content">
                <h3>2011</h3>
                <ul>
                    <li>MCTS: Windows Application Development with Microsoft .NET Framework 4</li>
                    <p><strong>Microsoft</strong></p>
                    <li>Instructional Techniques</li>
                    <p><strong>Execu-train</strong></p>
                </ul>
            </div>
        </div>
        <div className="timeline-container timeline-right">
            <div className="timeline-content">
                <h3>2010</h3>
                <ul>
                    <li>MCTS: SQL 2008 Business Intelligence Development and Maintenance
Implementing and Maintaining MSSQL 2008 Analysis Services
Implementing and Maintaining MS SQL 2008 Integration Sercvices</li>
                    <p><strong>Microsoft</strong></p>
                </ul>
            </div>
        </div>
        <div className="timeline-container timeline-left">
            <div className="timeline-content">
                <h3>2002</h3>
                <ul>
                    <li>Leadership Training for Managers</li>
                    <p><strong>Dale Carnegie</strong></p>
                </ul>
            </div>
        </div>
        <div className="timeline-container timeline-right">
            <div className="timeline-content">
                <h3>2000</h3>
                <ul>
                    <li>National Diploma: Information Technology</li>
                    <p><strong>Technikon S.A. (Merged with UNISA) - South Africa</strong></p>
                </ul>
            </div>
        </div>
        <div className="timeline-container timeline-left">
            <div className="timeline-content">
                <h3>1993</h3>
                <ul>
                    <li>National Senior Certificate with Matriculation Exemption</li>
                    <p><strong>Suid-Natal High School, Port Shepstone, South Africa</strong></p>
                </ul>
            </div>
        </div>
    </div>
</div>
    );
}

export default Education;