import React from 'react';
import GraphLine from '../components/GraphLine';
import './Skills.css';

const Skills = () => {
    return (
<div className="page-container">
    <div className="top-separator" />
    <div className="page-info">
        <p><strong>My technical skills</strong> are listed and rated out of ten. I've also included the number of years I have used the technology or technology stack.</p>
    </div>
    <div className="graphs-container">
        <div className="skills-graph">
            <h4>Core Technical skills</h4>
            <GraphLine color="red" name="C#" value="10" attribute="17y" />
            <GraphLine color="red" name="SQL Server" value="9" attribute="20y" />
            <GraphLine color="red" name="JavaScript" value="5" attribute="6y" />
        </div>
        <div className="skills-graph">
            <h4>Other Web Technologies</h4>
            <GraphLine color="blue" name="ASP.NET MVC, Core" value="8" attribute="10y" />
            <GraphLine color="blue" name="HTML and CSS" value="7" attribute="6y" />
            <GraphLine color="blue" name="Typescript, jQuery" value="5" attribute="3y" />
            <GraphLine color="blue" name="React, Vue, Sass" value="5" attribute="2y" />
            <GraphLine color="blue" name="PHP" value="3" attribute="2y" />
        </div>
        <div className="skills-graph">
            <h4>UI, Mobile and Server-side technologies</h4>
            <GraphLine color="green" name="WPF, Silverlight, WinForms, XAML" value="10" attribute="16y" />
            <GraphLine color="green" name="NodeJS" value="5" attribute="1y" />
            <GraphLine color="green" name="Xamarin, UWP apps" value="4" attribute="1y" />
        </div>
        <div className="skills-graph">
            <h4>Other Database Technologies</h4>
            <GraphLine color="purple" name="MySql" value="6" attribute="4y" />
            <GraphLine color="purple" name="Entity Framework, nHibernate, LINQ to Entities" value="7" attribute="9y" />
            <GraphLine color="purple" name="BI, Cubes, Analysis Services" value="5" attribute="2y" />
        </div>
        <div className="skills-graph">
            <h4>Design</h4>
            <GraphLine color="orange" name="UML, Enterprise Architect, Visio" value="6" attribute="4y" />
            <GraphLine color="orange" name="diagrams.net" value="6" attribute="2y" />
        </div>
        <div className="skills-graph">
            <h4>Management</h4>
            <GraphLine color="pink" name="Project Management, Sprint planning, Backlog grooming" value="8" attribute="10y" />
        </div>
        <div className="skills-graph">
            <h4>Platform</h4>
            <GraphLine color="lime" name="BizTalk, SharePoint" value="6" attribute="3y" />
            <GraphLine color="lime" name="nopCommerce, Umbraco, SuiteCRM" value="4" attribute="2y" />
        </div>
        <div className="skills-graph">
            <h4>Reporting</h4>
            <GraphLine color="violet" name="Crystal Reports, Reporting Services, SQL Reporting" value="9" attribute="10y" />
        </div>
        <div className="skills-graph">
            <h4>Services</h4>
            <GraphLine color="brown" name="WCF Services, SOAP, WebAPI, RESTful, XSLT" value="7" attribute="5y" />
        </div>
    </div>
    <aside className="footer-info">
        <h3>Guidance on ratings:</h3>
            <ul>
                <li>1-2: Familiar with it, have some knowledge</li>
                <li>3-4: Has had training, but not used it in a project</li>
                <li>5-6: Used the technology in a project</li>
                <li>7-8: Very familiar with the technology and used it very often</li>
                <li>9-10: Proficient in the technology</li>
            </ul>
        </aside>
</div>
    )
}

export default Skills;