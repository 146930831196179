import React from 'react';

const Footer = () => {
    return (
        <footer>
            <p>&copy; 2022 - leonardbauling.com</p>
            <a href="https://za.linkedin.com/in/leonard-bauling-3377826">
                <img className="icon-small" src="./images/linkedin.png" alt="linked-in"></img></a>
        </footer>
    );
}

export default Footer;