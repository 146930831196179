import React, { useEffect } from 'react';
import Card from '../components/Card';
import './Home.css';
import gsap from 'gsap';

const Home = () => {
    useEffect(() => {
        gsap.set(".profile-bio img", { transformOrigin: "50%" });
        gsap.fromTo(".profile-bio img", { duration: 2, x: -400}, 
            { duration: 2, x:0, borderRadius: "50%", ease: "elastic" } );
        gsap.to(".profile-bio img", {duration: 3, rotation: 360, ease: "slow"});
      });
    return (
<div className="page-container">
    <div className="top-separator" />
    <div className="page-info">
        <p><strong>Welcome </strong>to my career site. It outlines my professional history from completing school to my current position at WiseTech Global and everything in-between.</p>
    </div>
    <section className="profile-bio">
        <img src="./images/profile1.png" alt="My profile" />
        <div className="profile-quote">
            <q>I love developing the software tools and applications to make businesses ROCK! I’m equally adapt to operate in any area of a software project, including designing, coding, testing and/or managing. I love being in the engine room and understanding the nuts-and-bolts of a solution or requirement. I maximize the moments of being in 'the zone', coding up a storm and enjoy discussing strategic vision with various stakeholders and laying out a road-map to achieve the dream, or future-vision. I love that our industry never stands still and that technology is always advancing. I often take the lead in a team environment and enjoy mentoring and clearing a path for others to realize their greatness. Family centers me and staying fit helps me maintain high-energy throughout the day.</q>
            <p className="author">- Leonard Bauling</p>
        </div>
    </section>
    <section className="cards">
        <Card cardImage="./images/skills.jpg" to="/skills" title="My Skills" content="View the range and rating of the technology skills I've acquired through the years." />
        <Card cardImage="./images/training.jpg" to="/education" title="My Education" content="View all my qualifications, certificates and completed courses that I've acumulated in my career so far." />
        <Card cardImage="./images/conference-room.jpg" to="/experience" title="My Experience" content="View the roles and positions I've filled over the last 20+ years, working in the IT industry. Also see a list of projects I've worked on." />
    </section>
</div>
    );
}

export default Home;