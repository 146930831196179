import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Skills from './pages/Skills';
import Education from './pages/Education';
import Experience from './pages/Experience';
import Footer from './components/Footer';

const App = () => {
  return (
<Router>
  <main className="page-main">
    <Navbar />
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/about" element={<About/>} />
      <Route path="/skills" element={<Skills/>} />
      <Route path="/education" element={<Education/>} />
      <Route path="/experience" element={<Experience/>} />
    </Routes>
  </main>
  <Footer />
</Router>
  );
}

export default App;
