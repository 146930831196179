import React from 'react';
import './BlockArticle.css';

const BlockArticle = (props) => {
    return (
        <article className="block-article-container">
            <div className="block-article-image">
                <img src={props.articleImage} alt='' />
            </div>
            <div className="block-article-content">
                <h2>{props.articleHeader}</h2>
                <p>{props.articleBody}</p>
                <p className="block-article-links">
                    {props.articleLink1 !== "" ? <a href={props.articleLink1} target="_blank" rel="noreferrer">{props.articleLink1Name}</a> : ""}
                    {props.articleLink2 !== "" ? <a href={props.articleLink2} target="_blank" rel="noreferrer">{props.articleLink2Name}</a> : ""}
                    {props.articleLink3 !== "" ? <a href={props.articleLink3} target="_blank" rel="noreferrer">{props.articleLink3Name}</a> : ""}
                </p>
            </div>
        </article>

    );
}

export default BlockArticle;