import React, { useState } from 'react';
import './Experience.css';

const Experience = () => {
    const [experienceType, setExperienceType] = useState('Work');
    return (
<div className="page-container">
    <div className="top-separator" />
    <div className="page-info">
        <p><strong>My experience </strong> is listed in the timeline on this page for both job descriptions as well as project experience.</p>
    </div>
    <div className="experience-tab">
        <button className={experienceType === "Work" ? "experience-tab-link focus" : "experience-tab-link"} onClick={() => {
                        setExperienceType('Work');
                        }}>Work History</button>
        <button className={experienceType === "Project" ? "experience-tab-link focus" : "experience-tab-link"} onClick={() => {
                        setExperienceType('Project');
                        }}>Project History</button>
    </div>
    {experienceType === "Work" ?
    <div>
        <div className="timeline">
            <div className="timeline-container timeline-left">
                <div className="timeline-content">
                <h3>2018-03-26 to current date</h3>
                    <div className="job-title-container">
                        <h4>WiseTech Global</h4>
                        <h5>Senior Software Developer / Software Engineering Team Leader</h5>
                    </div>
                    <p>Develop enhancements, bug-fixes and new features to world-leading global logistics platform. 
                        I initially started in the integration team. I designed, developed, tested and managed integration solution between WiseTech global clients, third-parties and government agencies etc.. I transitioned to sub-team leader.
                        I then moved to the WiseTech Academy team to get more exposure to modern front-end development. We develop and support a learning management system and portal for providing expert tuition to the logistics and supply-chain management industry as well as our range of software products.
                    </p>
                </div>
            </div>
            <div className="timeline-container timeline-right">
                <div className="timeline-content">
                    <h3>2015-03-17 to 2018-01-12</h3>
                    <div className="job-title-container">
                        <h4>Mix Telematics</h4>
                        <h5>Senior Lead Developer</h5>
                    </div>
                    <p>Maintain existing Transportation Management System and design and lead a team to develop a new modern web execution management portal.</p>
                    <p className="reason-leaving">Reason for leaving: Division closed down</p>
                </div>
            </div>
            <div className="timeline-container timeline-left">
                <div className="timeline-content">
                    <h3>2008-03-03 to 2014-11-30</h3>
                    <div className="job-title-container">
                        <h4>Business Connection</h4>
                        <h5>Principal Software Developer</h5>
                    </div>
                    <h5>Solution Design Manager</h5>
                    <p>Lead team of developers to develop software for various clients, gather requirements, design (FDS and TDS), development, progress tracking and quality assurance. I then moved into the role of Solution Design manager.</p>
                    <p className="reason-leaving">Reason for leaving: Moved from permanent employee to contractor and then contract ended</p>
                </div>
            </div>
            <div className="timeline-container timeline-right">
                <div className="timeline-content">
                    <h3>2004-04-01 to 2007-11-30</h3>
                    <div className="job-title-container">
                        <h4>Legal Interact</h4>
                        <h5>Research and Development Manager</h5>
                    </div>
                    <p>Manage team of developers. Re-engineer all software packages into a single CRM solution. Liaise with clients, design solutions, manage projects, ensure quality and standards are maintained.</p>
                    <p className="reason-leaving">Reason for leaving: I wanted to stay current with new technologies and join a company that creates diverse Line-Of-Business applications using recent technologies.</p>
                </div>
            </div>
            <div className="timeline-container timeline-left">
                <div className="timeline-content">
                    <h3>2003-03-01 to 2004-03-31</h3>
                    <div className="job-title-container">
                        <h4>Bauling Business Applications</h4>
                        <h5>Owner / Sole Trader</h5>
                    </div>
                    <p>Provide custom software development solutions to clients</p>
                    <p className="reason-leaving">Reason for leaving: Legal interact required an in-house development team.</p>
                </div>
            </div>
            <div className="timeline-container timeline-right">
                <div className="timeline-content">
                    <h3>2002-03-01 to 2003-02-28</h3>
                    <div className="job-title-container">
                        <h4>Legal Interact</h4>
                        <h5>Product Manager / Software Developer</h5>
                    </div>
                    <p>Manage support team for Legal Accounting software. Gather requirements from clients, design, develop, test and implement solutions.</p>
                    <p className="reason-leaving">Reason for leaving: An opportunity presented itself to become self-employed, which I took advantage of.</p>
                </div>
            </div>
            <div className="timeline-container timeline-left">
                <div className="timeline-content">
                    <h3>2000-03-01 to 2002-02-28</h3>
                    <div className="job-title-container">
                        <h4>Green Africa Shipping</h4>
                        <h5>Software Developer</h5>
                    </div>
                    <p>Design and Implement a new internal Debtors and Creditors system to integrate with Accounting system.
                        Support various International Shipping software for Evergreen Shipping Company.</p>
                    <p className="reason-leaving">Reason for leaving:	I wanted to focus more on development and less on technical support.</p>
                </div>
            </div>
        </div>
    </div>
    :
    <div>
        <div className="timeline">
            <div className="timeline-container timeline-left">
                <div className="timeline-content">
                    <h3>2021 - present day</h3>
                    <div className="job-title-container">
                        <h4>WiseTech Academy</h4>
                        <h5>WiseTech Global</h5>
                    </div>
                    <p>Develop and maintain a learning management system and student portal that provides expert tuition for the logistics and supply-chain management idustry as well as software products and internal training. The management system is implemented using .NET Core MVC, .NET Core WebAPI, Sql Server and Entity Framework Core, while the website and student portal uses Umbraco, .NET and Vue web components and integrates with the WebAPI.</p>
                </div>
            </div>
            <div className="timeline-container timeline-left">
                <div className="timeline-content">
                    <h3>2019 - 2021</h3>
                    <div className="job-title-container">
                        <h4>Cargowise One - eHub</h4>
                        <h5>WiseTech Global</h5>
                    </div>
                    <p>Maintain existing integration solution for Cargowise One. The integration layer uses the latest .NET Framework and is implemented with a blend of the following technologies: C#, XSLT, XML schemas, XPath, SQL, Entity Framework, EDI, MVC, WCF, WebAPI and Biztalk.</p>
                </div>
            </div>
            <div className="timeline-container timeline-right">
                <div className="timeline-content">
                    <h3>2016 - 2018</h3>
                    <div className="job-title-container">
                        <h4>Transportation Management System</h4>
                        <h5>Mix Telematics</h5>
                    </div>
                    <p>Maintain existing fleet management solution. Optimize large data volumes based on tracking data. Fix software defects and develop new features. Assist with design of the next generation of the software. The current solution uses SQL Server, Silverlight and Ria Services while the next gen solution uses ASP.NET MVC 6, Jquery, Kendo, Entity Framework, HTML 5, CSS 3 and other modern web technologies</p>
                </div>
            </div>
            <div className="timeline-container timeline-left">
                <div className="timeline-content">
                    <h3>2014 - 2015</h3>
                    <div className="job-title-container">
                        <h4>SuiteCRM</h4>
                        <h5>Bauling Business Applications</h5>
                    </div>
                    <p>Used SuiteCRM as a platform to develop solutions for Enquiries, Lodge reservations, Sales and Lead management and various other functions</p>
                </div>
            </div>
            <div className="timeline-container timeline-right">
                <div className="timeline-content">
                    <h3>2013 - 2014</h3>
                    <div className="job-title-container">
                        <h4>My Top Dog eLearning</h4>
                        <h5>Business Connexion</h5>
                    </div>
                    <p>Re-engineer existing portal with a HTML5, CSS3, MVC Responsive application, using nopCommerce, entity framework, SQL and reporting services</p>
                </div>
            </div>
            <div className="timeline-container timeline-left">
                <div className="timeline-content">
                    <h3>2012</h3>
                    <div className="job-title-container">
                        <h4>Bonus Incentive System</h4>
                        <h5>Business Connexion</h5>
                    </div>
                    <p>Re-engineered an existing VB application that tracks and calculates bonuses based on varied metrics, using Silverlight 4, SQL Server 2008 R2, WCF Services, Enterprise Library.4.1. My role is tech-lead on the project. </p>
                </div>
            </div>
            <div className="timeline-container timeline-right">
                <div className="timeline-content">
                    <h3>2012</h3>
                    <div className="job-title-container">
                        <h4>NAFCOR Uranium Tracking System</h4>
                        <h5>Business Connexion</h5>
                    </div>
                    <p>Created a new application to replace an obsolete system in order to track the uranium through the production and delivery processes. We used .NET Ria Services linked to Entity Framework models based on a SQL database. We also used an OLAP cube to provide interactive reporting and BI capabilities. My role was tech-lead and senior developer.</p>
                </div>
            </div>
            <div className="timeline-container timeline-left">
                <div className="timeline-content">
                    <h3>2010</h3>
                    <div className="job-title-container">
                        <h4>SASOL Environmental Risk Engineering application</h4>
                        <h5>Business Connexion</h5>
                    </div>
                    <p>I designed and lead a team of 5 developers to create an application which replaced various manual processes and spreadsheets. We used SQL, Windows Forms and Crystal Reports.</p>
                </div>
            </div>
            <div className="timeline-container timeline-right">
                <div className="timeline-content">
                    <h3>2006</h3>
                    <div className="job-title-container">
                        <h4>Practice Manager</h4>
                        <h5>Legal Interact</h5>
                    </div>
                    <p>The application was developed using .NET, Windows Forms, SQL Server and Crystal Reports. The application includes CRM, legal accounting, litigation, debt-collecting and conveyancing components and is aimed at various size law firms. I managed a team of 5 developers and was involved in all aspects of the SDLC.</p>
                </div>
            </div>
        </div>
    </div>
    }
</div>
    );
}

export default Experience;